import { api, log } from ":mods";
import { ENDPOINTS } from "../const";

export async function getUserEvents() {
      return api
        .getAuth(ENDPOINTS.get_events)
        .then(async (res) => {
          // console.log("login res :: ", res);
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          log.dev.info("get_user_events error :: ", err);
          return Promise.reject(err);
        });
    }
    